import { Button, Filter, ViewTable, Layout } from "@/components";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import React from "react";

export default class StockRecord extends React.PureComponent {
  private table = React.createRef<ViewTable>()

  private queryParams = {}

  private queryStockList = () => {
    this.table.current?.reset(this.queryParams);
  }

  private columns = [
    {
      title: '商品名称',
      dataIndex: 'productName',
      width: 200,
    },
    {
      title: '规格',
      dataIndex: 'skuName',
      width: 260,
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      align: 'left',
      width: 240,
    },
    {
      title: '进价',
      dataIndex: 'price',
      align: 'right',
      width: 120,
    },
    {
      title: '仓库',
      dataIndex: 'repositoryName',
      align: 'center',
      width: 140,
    },
    {
      title: '出/入库',
      dataIndex: 'changeType',
      width: 100,
      align: 'center',
      render: (v) => {
        if (v === '2') {
          return <span style={{color: '#f5222d'}}>
            <i className="iconfont icon-chuku"/>
            出库
          </span>
        }
        if (v === '1') {
          return <span style={{color: '#52c41a'}}>
            <i className="iconfont icon-ruku" style={{fontSize: 14}}/>
            入库
          </span>
        }
        return '--'
      }
    },
    {
      title: '数量',
      dataIndex: 'changeCount',
      align: 'right',
      width: 120,
      render: (v, row) => {
        if (row.changeType === '2') {
          return <span style={{color: '#f5222d'}}>-{v}</span>
        }
        if (row.changeType === '1') {
          return <span style={{color: '#52c41a'}}>+{v}</span>
        }
        return '--'
      }
    },
    {
      title: '摘要',
      align: 'center',
      width: 120,
      dataIndex: 'stockTypeDesc',
    },
    {
      title: '库存量',
      dataIndex: 'stockCount',
      width: 100,
      align: 'center',
    },
    {
      title: '时间',
      dataIndex: 'createdAt',
      width: 180,
      align: 'center',
    },
  ]

  private fields = [
    {
      type: 'select',
      field: 'repositoryId',
      name: '仓库',
      width: 150,
      options: async () => {
        const data: any = await WebApi.repository_list();
        return data.map(item => {
          return {
            name: item.repositoryName,
            value: item.repositoryId,
          };
        });
      }
    },
    {
      type: 'select',
      field: 'supplierId',
      name: '供应商',
      width: 180,
      options: async () => {
        const data: any = await WebApi.supplier_list();
        return data.map(item => {
          return {
            name: item.supplierName,
            value: item.supplierId,
          };
        });
      }
    },
    {
      type: 'input',
      field: 'productName',
      name: '搜索',
      placeholder: '商品名称',
    },
  ]

  render() {
    return <Layout.Page>
      <Layout.Toolbar title={routerMap.stockRecord.name}>
        <Button.Refresh onClick={this.queryStockList}></Button.Refresh>
      </Layout.Toolbar>
      <Filter
        fields={this.fields as any}
        onChange={this.onFilterChange}
      />
      <ViewTable
        flex1
        ref={this.table}
        fetch={this.fetch}
        columns={this.columns as any}
      />
    </Layout.Page>
  }

  private onFilterChange = values => {
    this.queryParams = values;
    this.queryStockList();
  }

  private fetch = data => {
    return WebApi.stock_record_paging({...data})
  }
}